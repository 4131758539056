import { React, Page, onMount, onUpdate } from 'lib'
import { useLocation } from 'react-use'
import Homepage from './_homepage'

import { Theme } from 'app'

const Root = () => {
  const location = useLocation()
  const { height } = Theme.hooks.size()
  const isMobile = Theme.hooks.down('mid')

  return (
    <Page showChat center root>
      <Homepage/>
    </Page>
  )
}

export default Root
